//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding component variables.
//  ================================================================================================

// Trio de cards presentes da rota PEDIDOS RECEBIDOS
.card-pedidos-mensais {
    background: #f9f9f9;
    color: #5E5873;
    border-radius: 16px;
    font-weight:100;
}
.text-card-pedidos-mensais {
font-size: 22px;
font-weight: bold;
}
.text-footer-card-pedidos-mensais {
    font-size: 16px;
    font-weight: normal;
    color: #b3b3b3;
    }
// Cards representativos de cada mês da rota PEDIDOS RECEBIDOS
    .card-pedidos-total{
        background: #f9f9f9;
    }
    .text-qtd-pedidos{
        font-size: 24;
        color: #232360;
        font-weight:bolder;
        padding-top: 25px;
        padding-bottom: 25px;

    }
    .text-titulo-qtd-pedidos{
        font-size: 20;
        color: #a2a1a7;
        font-weight:normal;
    }

// cards de montadoras ROTA: gestão montadoras
.card-montadoras {
     .row {
      margin: 0;
    }
    // content container
    .card-montadoras-content {
        margin-top: 20px;
        margin-bottom: 20px;
        background-color: rgb(255, 255, 255);
        border-radius: 6px;
        box-shadow: 0 0 8px 0 rgba($black, 0.14);
        border: #232360;
      // image
      img {
        position: relative;
      }
    }
  }
.hide-on-mobile { 
  @media screen and (max-width: 800px) {
             display: none !important; 
      }
}
.margin-on-mobile { 
  @media screen and (max-width: 800px) {
            margin-top:1rem;
      }
}
.resize-on-mobile { 
  @media screen and (max-width: 800px) {
             width: 10px;
            height: 10px;

      }
}


  // Trio de cards. FATURAMENTO GERAL
.card-faturamento-mensal {
    background: #f9f9f9;
    color: #5E5873;
    border-radius: 16px;
    font-weight:100;
    height: 220px;
}
.text-card-faturamento-mensal {
    font-size: 36;
    font-weight: bold;
    }
.title-card-faturamento-mensal {
        font-size: 22px;
        font-weight: 500;
        }
.text-footer-card-faturamento-mensal {
            font-size: 16px;
            font-weight: normal;
            color: #b3b3b3;
            }
.chart-faturamento-anual {
                background: #f9f9f9;
                color: #5E5873;
                border-radius: 16px;
                font-weight:100;
            }
.highlight-text-card{
    color: #299702
}


.input-field::placeholder {
    color: #ff0055;
    background-color: #1bb343;
  }
  
  .input-field:focus {
    border: 1px solid #fc5c7d;
    box-shadow: 0px 0px 5px 0.5px #fc5c7d;
  }
  
  .input-field:focus ~ .form-label {
    color: #fc5c7d;
  }
  
  .input-field:focus::placeholder {
    color: transparent;
  }

.btn-size{
 width: 5px;
}