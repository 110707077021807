/*================================================================================

================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
// CSS DO ARQUIVO LOGIN.JS //
.loginBackground{
    background-image: url('../login-background-dark.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    // border-radius: 100px 0px 0px 100px;
    width: 70%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 1300px) {
      width: 60%;
    }
    @media only screen and (max-width: 1100px) {
      width: 55%;
    }
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
  
  .loginForm {
    width: 30%;
  height: 100vh;
  margin-right: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: margin-right 0.3s; /* Add a smooth transition effect */

  @media only screen and (max-width: 1300px) {
    margin-right: 60px;
    width: 40%;
  }
  @media only screen and (max-width: 1100px) {
    margin-right: 45px;
    width: 45%;
  }
  @media only screen and (max-width: 900px) {
    margin-right: 0;
    width: 100%;
  }
  }
